<template>
  <section
    v-bind:style="[
      asuulga.bgDetailColor != null
        ? {
            background: asuulga.bgDetailColor.hex,
            'overflow-y': 'hidden',
            'border-color': asuulga.bgDetailColor.hex,
          }
        : {
            background: '#fff',
            'overflow-y': 'hidden',
            'border-color': '#e89e57',
          },
    ]"
    v-if="asuulga"
  >
    <v-container class="py-0">
      <v-row no-gutters>
        <v-col md="8" class="mx-auto">
          <v-row no-gutters class="d-flex justify-space-between mt-4">
            <div class="text-start" v-if="uuser">
              <h3>{{ asuulga.title }}</h3>
              <p>{{ userData.ref.path }}</p>
            </div>
            <!-- <v-btn icon>
              <v-icon v-if="isGrid" @click="isGrid = !isGrid">mdi-grid</v-icon>
              <v-icon v-else @click="isGrid = !isGrid"
                >mdi-format-list-bulleted-square
              </v-icon>
            </v-btn> -->
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-container v-if="isGrid" style="min-height: 480px; overflow-y: scroll">
        <v-row no-gutters>
          <v-col md="6" class="mx-auto">
            <v-toolbar flat color="#ececec">
              <v-tabs v-model="tab" align-with-title>
                <v-tabs-slider color="black"></v-tabs-slider>
                <v-tab
                  v-for="(cat, catindex) in categories"
                  :key="catindex"
                  class="text-capitalize"
                >
                  {{ cat.name }} ({{ cat.questions.length }})
                </v-tab>
              </v-tabs>
            </v-toolbar>

            <v-tabs-items
              v-model="tab"
              v-bind:style="[
                asuulga.bgDetailColor != null
                  ? {
                      background: asuulga.bgDetailColor.hex,
                      'overflow-y': 'hidden',
                      'border-color': asuulga.bgDetailColor,
                    }
                  : {
                      background: '#fff',
                      'overflow-y': 'hidden',
                      'border-color': '#e89e57',
                    },
              ]"
            >
              <v-tab-item v-for="(cat, catindex) in categories" :key="catindex">
                <v-card
                  elevation="0"
                  :border="question.countAnswers > 0 ? 'left' : ''"
                  style="border-color: rgb(236, 236, 236)"
                  class="px-4 pt-8 mt-2 outlined-card"
                  v-for="(question, qindex) in cat.questions"
                  :key="qindex"
                >
                  <h4>
                    {{ qindex + 1 }}. {{ question.name }}
                    <!-- <span class="secondary--text">
                      {{ question.countAnswers }}/{{
                        question.answerLimit
                      }}</span
                    > -->
                  </h4>
                  <v-list>
                    <v-list-item
                      class="px-1"
                      v-for="answer in question.answers"
                      :key="answer.id"
                      @click="
                        _answerDefaultQuestion(question, answer, category);
                        _saveAnswer(question, answer, category);
                      "
                    >
                      <v-list-item-avatar class="mr-0">
                        <v-icon
                          size="24"
                          v-if="answer.answered == true"
                          style="color: red"
                        >
                          mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon v-else size="24">
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="text-wrap"
                          v-text="answer.name"
                        />
                      </v-list-item-content>
                      <!-- 
                      <v-list-item-action v-if="answer.score">
                        <p>{{ answer.score }}</p>
                      </v-list-item-action> -->
                    </v-list-item>

                    <v-list-item style="min-height: 0px">
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap" />
                      </v-list-item-content>

                      <v-list-item-action
                        class="ma-0"
                        v-if="question.countAnswers > 0"
                      >
                        <v-chip
                          class="font-weight-bold"
                          @click="_clearAnswers(question, category)"
                        >
                          Арилгаx ({{ question.countAnswers }})</v-chip
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row no-gutters>
          <v-col md="6" offset-md="3">
            <div v-for="(cat, catindex) in categories" v-bind:key="catindex">
              <br />
              <v-btn
                elevation="0"
                class="py-4 font-weight-bold my-0"
                color="primary"
              >
                БҮЛЭГ. {{ cat.name }}</v-btn
              >
              <div>
                <v-alert
                  class="pa-4 mt-0 mb-2"
                  color="red"
                  :border="question.countAnswers > 0 ? 'left' : ''"
                  colored-border
                  v-for="(question, qindex) in cat.questions"
                  :key="qindex"
                >
                  <h4>
                    {{ qindex + 1 }}. {{ question.name }}
                    <span class="red--text">
                      {{ question.countAnswers }}/{{
                        question.answerLimit
                      }}</span
                    >
                  </h4>
                  <v-list>
                    <v-list-item
                      class="px-1"
                      v-for="answer in question.answers"
                      :key="answer.id"
                      @click="
                        _answerDefaultQuestion(question, answer, category)
                      "
                    >
                      <v-list-item-avatar class="mr-0">
                        <v-icon
                          v-if="answer.answered == true"
                          style="color: red"
                        >
                          mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon v-else>
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="text-wrap"
                          v-text="answer.name"
                        />
                      </v-list-item-content>

                      <!-- <v-list-item-action v-if="answer.score">
                        <p>{{ answer.score }}</p>
                      </v-list-item-action> -->
                    </v-list-item>

                    <v-list-item style="min-height: 0px">
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap" />
                      </v-list-item-content>

                      <v-list-item-action
                        class="ma-0"
                        v-if="question.countAnswers > 0"
                      >
                        <v-chip
                          class="font-weight-bold"
                          @click="_clearAnswers(question, category)"
                        >
                          Арилгаx ({{ question.countAnswers }})</v-chip
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-alert>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- <v-row no-gutters class="mb-16">
        <v-col md="6" offset-md="3">
          <v-btn
            color="secondary"
            class="font-weight-bold"
            @click="_sendAsuulga"
          >
            ИЛГЭЭX
          </v-btn>
        </v-col>
      </v-row> -->
    </v-container>

    <section class="container container-fluid py-4">
      <v-row no-gutters>
        <v-col md="6" offset-md="3">
          <v-btn
            class="font-weight-bold bg-gradient-danger"
            dark
            @click="sendDialog = true"
          >
            ИЛГЭЭX
          </v-btn>
        </v-col>
      </v-row>
    </section>
    <v-col class="text-center" cols="12">
      <small
        >This content is neither created nor endorsed by Asuulga.MN. Report
        Abuse - Terms of Service - Privacy Policy</small
      >
      <p>{{ new Date().getFullYear() }} — <strong>Asuulga.mn</strong></p>
    </v-col>

    <v-dialog v-model="sendDialog" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Илгээxийг xүсэж байна уу?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="sendDialog = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_sendAsuulga"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Илгээx</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";

export default {
  data: () => ({
    toBeNumberOfParticipants: null,
    sendDialog: false,
    isGrid: true,
    tab: null,
    items: ["web", "shopping", "videos", "images", "news"],
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

    asuulga: null,
    categories: null,
    prePath: null,
    uuser: {
      type: Object,
      required: true,
    },
    school: null,
    bgDetailColor: "#ececec",
  }),
  props: {
    asuulgaRefPath: {
      type: String,
      required: true,
    },
    // bulegId: {
    //   type: String,
    //   required: true,
    // },
    // teacherName: {
    //   type: String,
    //   required: true,
    // },
    // teacherRefPath: {
    //   type: String,
    //   required: true,
    // },
    // teacherLessons: {
    //   type: String,
    //   required: true,
    // },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this._getData();
  },
  methods: {
    _saveAnswer(question, answer, category) {
      console.log(question, answer, category);
      var answered = question.answers.find((answer) => answer.answered == true);
      if (answered && answered.name == answer.name) {
        this.asuulga.ref
          .collection("currently-answering")
          .doc(this.userData.id + "-" + this.teacherName)
          .set({ createdAt: new Date() })
          .then(() => {
            console.log("saved in ..");
            this.asuulga.ref
              .collection("currently-answering")
              .doc(this.userData.id + "-" + this.teacherName)
              .collection("categories")
              .doc(category.id)
              .set({ name: category.name, createdAt: new Date() })
              .then(() => {
                this.asuulga.ref
                  .collection("currently-answering")
                  .doc(this.userData.id + "-" + this.teacherName)
                  .collection("categories")
                  .doc(category.id)
                  .collection("questions")
                  .doc(question.id)
                  .set({
                    name: question.name,
                    createdAt: new Date(),
                  })
                  .then(() => {
                    this.asuulga.ref
                      .collection("currently-answering")
                      .doc(this.userData.id + "-" + this.teacherName)
                      .collection("categories")
                      .doc(category.id)
                      .collection("questions")
                      .doc(question.id)
                      .collection("answers")
                      .doc(answer.id)
                      .set({
                        name: answer.name,
                        createdAt: new Date(),
                        answered: true,
                      });
                  });
              });
          });
      } else if (answered == undefined) {
        this.asuulga.ref
          .collection("currently-answering")
          .doc(this.userData.id + "-" + this.teacherName)
          .collection("categories")
          .doc(category.id)
          .get()
          .then((doc) => {
            if (doc.exists)
              doc.ref.get().then((doc) => {
                if (doc.exists)
                  this.asuulga.ref
                    .collection("currently-answering")
                    .doc(this.userData.id + "-" + this.teacherName)
                    .collection("categories")
                    .doc(category.id)
                    .collection("questions")
                    .doc(question.id)
                    .collection("answers")
                    .get()
                    .then((docs) => {
                      docs.forEach((doc) => {
                        doc.ref.delete();
                      });
                    });
              });
          });
      }
    },
    async _getData() {
      fb.db
        .doc(this.asuulgaRefPath)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            this.asuulga = doc.data();
            this.asuulga.ref = doc.ref;
            this.asuulga.id = doc.id;
            if (
              this.asuulga.bgDetailColor != null &&
              this.asuulga.bgDetailColor !== undefined
            ) {
              this.bgDetailColor = this.asuulga.bgDetailColor;
            }

            await this._findCategories();
          } else {
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    // _getWomenMenData(ddata) {
    //   if (
    //     this.uuser.gender != undefined &&
    //     this.uuser.gender != null &&
    //     this.uuser.gender == 0
    //   ) {
    //     ddata.numberOfVotesOfMen = fb.firestore.FieldValue.increment(1);
    //   } else if (
    //     this.uuser.gender != undefined &&
    //     this.uuser.gender != null &&
    //     this.uuser.gender == 1
    //   ) {
    //     ddata.numberOfVotesOfWomen = fb.firestore.FieldValue.increment(1);
    //   }
    //   return ddata;
    // },
    async _getTotalScore(categories, batch) {
      var scored = 0;
      var depId = await this.uuser[
        "department-" + this.school.currentYear
      ].get();

      var programId = await this.uuser[
        "program-" + this.school.currentYear
      ].get();

      for (var cat of categories) {
        cat.catScored = 0;
        for (var question of cat.questions) {
          question.questionScored = 0;

          for (var answer of question.answers) {
            if (answer.answered) {
              question.questionScored = question.questionScored + answer.score;
              question.answered = true;
              //for answer
              var answerData = {
                numberOfVotes: fb.firestore.FieldValue.increment(1),
              };
              batch.set(answer.ref, this._getWomenMenData(answerData), {
                merge: true,
              });
              //for program inside the answerrrr
              if (programId.exists) {
                var answerProgramData = {
                  numberOfVotes: fb.firestore.FieldValue.increment(1),
                  departmentName: depId.data().name,
                  depRef: depId.ref,
                  programName: programId.data().name,
                  programRef: programId.ref,
                };
                batch.set(
                  answer.ref.collection("programs").doc(programId.id),
                  this._getWomenMenData(answerProgramData),
                  { merge: true }
                );
              }
            }
          }
          //for question
          if (question.answered) {
            var questionData = {
              numberOfVotes: fb.firestore.FieldValue.increment(1),
            };
            batch.set(question.ref, this._getWomenMenData(questionData), {
              merge: true,
            });
            if (depId.exists) {
              var depData = {
                numberOfVotes: fb.firestore.FieldValue.increment(1),
                name: depId.data().name,
              };
              batch.set(
                question.ref.collection("departments").doc(depId.id),
                this._getWomenMenData(depData),
                { merge: true }
              );
            }
          }
          cat.catScored = cat.catScored + question.questionScored;
        }
        scored = scored + cat.catScored;
      }
      return scored;
    },
    // async _saveToDepartmentProgram(categories, batch, depId) {
    //   var scored = 0;
    //   for (var cat of categories) {
    //     cat.catScored = 0;
    //     for (var question of cat.questions) {
    //       question.questionScored = 0;

    //       for (var answer of question.answers) {
    //         if (answer.answered) {
    //           question.questionScored = question.questionScored + answer.score;
    //           question.answered = true;
    //           //for answer
    //           var answerData = {
    //             numberOfVotes: fb.firestore.FieldValue.increment(1),
    //             answerName: answer.name,
    //             answerRef: answer.ref,
    //           };
    //           answerData = this._getWomenMenData(answerData);
    //           batch.set(
    //             this.asuulga.ref
    //               .collection("departments")
    //               .doc(depId.id)
    //               .collection("categories")
    //               .doc(cat.id)
    //               .collection("questions")
    //               .doc(question.id)
    //               .collection("answers")
    //               .doc(answer.id),
    //             this._getWomenMenData(answerData),
    //             { merge: true }
    //           );

    //           // for program inside the answerrrr
    //           // if (programId.exists) {
    //           //   var answerProgramData = {
    //           //     numberOfVotes: fb.firestore.FieldValue.increment(1),
    //           //   };
    //           //   batch.set(
    //           //     answer.ref.collection("programs").doc(programId.id),
    //           //     this._getWomenMenData(answerProgramData),
    //           //     { merge: true }
    //           //   );
    //           // }
    //         }
    //       }
    //       //for question
    //       if (question.answered) {
    //         cat.answered = true;
    //         var questionData = {
    //           numberOfVotes: fb.firestore.FieldValue.increment(1),
    //           questionName: question.name,
    //           questionRef: question.ref,
    //           questionId: question.id,
    //         };
    //         batch.set(
    //           this.asuulga.ref
    //             .collection("departments")
    //             .doc(depId.id)
    //             .collection("categories")
    //             .doc(cat.id)
    //             .collection("questions")
    //             .doc(question.id),
    //           this._getWomenMenData(questionData),
    //           {
    //             merge: true,
    //           }
    //         );
    //       }
    //       cat.catScored = cat.catScored + question.questionScored;
    //     }
    //     scored = scored + cat.catScored;
    //     if (cat.answered) {
    //       var catData = {
    //         numberOfVotes: fb.firestore.FieldValue.increment(1),
    //         catName: cat.name,
    //         catRef: cat.ref,
    //       };
    //       batch.set(
    //         this.asuulga.ref
    //           .collection("departments")
    //           .doc(depId.id)
    //           .collection("categories")
    //           .doc(cat.id),
    //         this._getWomenMenData(catData),
    //         {
    //           merge: true,
    //         }
    //       );
    //     }
    //   }
    //   return scored;
    // },
    async _sendAsuulga() {
      var _this = this;
      var batch = fb.db.batch();
      this.categories.forEach((cat) => {
        cat.questions.forEach((question) => {
          question.answers.forEach((answer) => {
            if (answer.answered) {
              console.log("answered: ", answer.ref.path, answer.name);
              batch.set(
                question.ref.collection("answers").doc(answer.id),
                {
                  numberOfAnswered: fb.firestore.FieldValue.increment(1),
                  answerId: answer.id,
                  questionId: question.id,
                },
                { merge: true }
              );
              batch.set(
                question.ref
                  .collection("answers")
                  .doc(answer.id)
                  .collection("results")
                  .doc(this.userData["STUDENT_GROUP_ID"].toString()),
                {
                  numberOfAnswered: fb.firestore.FieldValue.increment(1),
                },
                { merge: true }
              );
            }
          });
        });
      });
      batch.commit().then(() => {
        var GROUP_ID = this.userData["STUDENT_GROUP_ID"]
          ? this.userData["STUDENT_GROUP_ID"]
          : "TEST";

        var allReadQuestions = [];

        _this.asuulga.ref
          .collection("results-per-groups")
          .doc(GROUP_ID.toString())
          .collection("questions")
          .get()
          .then((questions) => {
            questions.forEach((question) => {
              let q = question.data();
              q.id = question.id;
              allReadQuestions.push(q);
            });

            var batch2 = fb.db.batch();
            this.categories.forEach((cat) => {
              cat.questions.forEach((ques) => {
                var newAnswerFields = {};
                var foundQuestion = allReadQuestions.find(
                  (q) => q.id == ques.id
                );

                ques.answers.forEach((ans) => {
                  if (ans.answered) {
                    console.log(
                      "answered: ",
                      ans.ref.path,
                      ans.name,
                      foundQuestion
                    );
                    if (foundQuestion == undefined) {
                      newAnswerFields[ans.id] = {
                        answerName: ans.name,
                        answerIndex: ans.answerIndex,
                        numberOfAnswered: 1,
                      };
                    } else {
                      if (foundQuestion.answers[ans.id]) {
                        newAnswerFields[ans.id] = {
                          answerName: ans.name,
                          answerIndex: ans.answerIndex,
                          numberOfAnswered:
                            foundQuestion.answers[ans.id].numberOfAnswered + 1,
                        };
                      } else {
                        newAnswerFields[ans.id] = {
                          answerName: ans.name,
                          answerIndex: ans.answerIndex,
                          numberOfAnswered: 1,
                        };
                      }
                    }
                    console.log(newAnswerFields);
                    batch2.set(
                      _this.asuulga.ref
                        .collection("results-per-groups")
                        .doc(GROUP_ID.toString())
                        .collection("questions")
                        .doc(ques.id),
                      {
                        categoryIndex: cat.categoryIndex,
                        questionIndex: ques.questionIndex,
                        questionName: ques.name,
                        answers: newAnswerFields,
                      },
                      { merge: true }
                    );
                  }
                });
              });
            });
            batch2.commit().then(() => {
              console.log("completed22222");
            });
          });

        //   .set(
        //     {
        //       numberOfAnswered: fb.firestore.FieldValue.increment(1),
        //       questions:
        //     },
        //     { merge: true }
        //   )
        //   .then(() => {

        //     // console.log("completed!!!", GROUP_ID, this.asuulga.ref.path);
        //     // _this.asuulga.ref
        //     //   .collection("answered-results")
        //     //   .doc(GROUP_ID.toString())
        //     //   .collection("answered")
        //     //   .doc()
        //     //   .set({
        //     //     GROUP_ID: GROUP_ID,
        //     //     PERSON_ID: this.userData["PERSON_ID"]
        //     //       ? this.userData["PERSON_ID"]
        //     //       : "TEST",
        //     //   });
        //   });
        // //   .then(() => {
        // //     this.sendDialog = false;
        // //   });
      });
    },
    _clearAnswers(question, category) {
      for (var answer of question.answers) {
        answer.answered = false;
      }
      question.countAnswers = 0;
      this.asuulga.ref
        .collection("currently-answering")
        .doc(this.userData.id + "-" + this.teacherName)
        .collection("categories")
        .doc(category.id)
        .get()
        .then((doc) => {
          if (doc.exists)
            doc.ref.get().then((doc) => {
              if (doc.exists)
                this.asuulga.ref
                  .collection("currently-answering")
                  .doc(this.userData.id + "-" + this.teacherName)
                  .collection("categories")
                  .doc(category.id)
                  .collection("questions")
                  .doc(question.id)
                  .collection("answers")
                  .get()
                  .then((docs) => {
                    docs.forEach((doc) => {
                      doc.ref.delete();
                    });
                  });
            });
        });
    },
    _answerDefaultQuestion(question, answer) {
      if (question.answerLimit) {
        if (question.countAnswers < question.answerLimit) {
          answer.answered = !answer.answered;
          if (answer.answered)
            question.countAnswers = question.countAnswers + 1;
          else question.countAnswers = question.countAnswers - 1;
        } else {
          if (answer.answered) {
            question.countAnswers = question.countAnswers - 1;
            answer.answered = !answer.answered;
          }
        }
      } else {
        answer.answered = !answer.answered;
        if (answer.answered) question.countAnswers = question.countAnswers + 1;
        else question.countAnswers = question.countAnswers - 1;
      }
    },
    _getQuestionsOfCategory(cat) {
      fb.db
        .collection(
          this.asuulgaRefPath + "/categories/" + cat.id + "/questions"
        )
        .where("deleted", "==", false)
        .orderBy("createdAt", "asc")
        .onSnapshot((querySnapshot) => {
          cat.questions = [];
          querySnapshot.forEach((doc) => {
            let question = doc.data();
            question.category = cat.name;
            // question.statData = null;
            // question.datacollection = null;

            question.id = doc.id;
            question.ref = doc.ref;
            question.answers = [];
            question.answered = false;
            question.countAnswers = 0;
            // question.currentAnswerActive = false;
            // question.answeredCount = null;
            // question.useranswer = null; // OORCHLOGDDOG DATAG OMNO NI ZAAVAL TODORHOILJ UTGA ONOOH

            question.answers = [];
            // this._getAnswers(question, doc.ref);

            doc.ref
              .collection("answers")
              .orderBy("answerIndex", "asc")
              .onSnapshot((querySnapshot) => {
                question.answers = [];
                querySnapshot.forEach(async (doc) => {
                  const answer = doc.data();
                  answer.id = doc.id;

                  await this.asuulga.ref
                    .collection("currently-answering")
                    .doc(this.userData.id + "-" + this.teacherName)
                    .collection("categories")
                    .doc(cat.id)
                    .collection("questions")
                    .doc(question.id)
                    .collection("answers")
                    .doc(answer.id)
                    .get()
                    .then((doc) => {
                      if (doc.exists) {
                        answer.answered = doc.data().answered;
                      } else {
                        answer.answered = false;
                      }
                    });
                  question.answers.push(answer);
                });
              });

            cat.questions.push(question);
            if (this.selectedQuestion == null) this.selectedQuestion = question;
            // }
          });
        });
    },
    async _findCategories() {
      this.asuulga.ref
        .collection("categories")
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.categories = [];
          querySnapshot.forEach((catDoc) => {
            const cat = catDoc.data();
            cat.id = catDoc.id;
            cat.ref = catDoc.ref;
            cat.questions = [];
            this._getQuestionsOfCategory(cat);
            this.categories.push(cat);
            if (this.categories.length === 1) {
              this.category = this.categories[0];
            }
          });
        });
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.outlined-card {
  border: 1px solid #e0e0e0;
}
.outlined-card:hover {
  border: 4px solid #e0e0e0;
}
</style>